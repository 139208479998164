/* VARIABLES */
:root {
    /* SKIL colors. Using HSL values so lightness relationships are clearer in development 😊 */
    --skil-green: hsl(188.37, 81.13%, 10.39%);
}

/* MUI DATAGRID TOOLBAR PANELS */

.MuiDataGrid-cell-alert {
    background-color: #a92513;
    color: #fff;
}

.MuiDataGrid-cell-warning {
    background-color: #e1c00e;
    color: #fff;
}

.MuiDataGrid-cell-success {
    background-color: #1e9f08;
    color: #fff;
}

/* MUI DATAGRID TOOLBAR PANELS */

.MuiDataGrid-panel .MuiPaper-root.MuiPaper-rounded {
    border-radius: 0;
}

.MuiDataGrid-panelFooter button.MuiButtonBase-root.MuiButton-text.MuiButton-textPrimary {
    color: #fff;
    background-color: var(--skil-green);
    border-radius: 0;
    margin: 0 0.25rem 0.25rem;
    padding: 0.25rem 1rem;
    border: none;
}

/* UTILITY CLASSES */

/* for breadcrumbs */
.pl-0 {
    padding-left: 0;
}

.bkg-inherit {
    background: inherit;
}

/* header quickfix */

.main-header .navbar {
    max-height: 50px;
}
